<template>
  <div :class="'desc' + (firstPhoto ? ' enable_photo ' : '')">
 
    
    <div class="body_photos" v-if="firstPhoto">
      <div class="info_gallery">
        <div :class="'cover ' + props.avatarRatio">
          <img :src="firstPhoto"  @click.stop.prevent="openGallery(0)" :alt="nmalt">
        </div>
      </div>
    </div>
  
    <div class="body_info">

      <GeneralShowMore
        :desktop-max-height="1200"
        :mobile-max-height="140" 
        >
        <div class="text" v-if="props.info" v-html="props.info"></div>
      
        <div class="thumb" v-if="props.items_photo?.length">
          <template v-for="(photo, photo_key) in props.items_photo">
            <NuxtLink :to="photo.photo" @click.stop.prevent="openGallery(photo_key)">
              <img :src="photo.url_s" :alt="nmalt">
            </NuxtLink>                              
          </template>
        </div> 

      </GeneralShowMore>

      <a :href="props.web" target="_blank" rel="nofollow" class="web" v-if="brand_package == 3 && web">
        <i class="icon-external"></i>
        {{props.web}}
      </a>
 

      <GeneralLightbox 
        v-if="lightboxShow"
        :index="lightboxIndex" 
        :photos="lightboxPhotos" 
        @close="lightboxShow = false" 
        />

 
    </div>
 
  </div>

</template>

<script setup>
 

const props = defineProps({
  info: {
    type: String,
    default: ''
  },
  nm: {
    type: String,
    default: ''
  },
  web: {
    type: String,
    default: ''
  },
  brand_package: {
    type: Number,
    default: ''
  },
  nmalt: {
    type: String,
    default: ''
  },
  items_photo: {
    type: Array,
    default: () => []
  },
  avatar: {
    type: String,
    default: ''
  },
  avatarRatio: {
    type: String,
    default: 'square'
  }
})

var lightboxShow = ref(false);
var lightboxIndex = ref(0);


const  firstPhoto = computed(() => {
  if(props.avatar)
    return props.avatar;
  return props.items_photo && props.items_photo.length ? props.items_photo[0].url_s : null;
})


const lightboxPhotos = computed(() => {


  var items = [];

  // if(props.avatar){
  //   let photo = {}; 
  //   photo.base = props.avatar;
  //   photo.zoom = props.avatar; 
  //   items.push(photo);  
  // }

  for(var i of props.items_photo){
    let photo = {}; 
    photo.base = i.url_m;
    photo.zoom = i.url_l; 
    items.push(photo);        
  }
  return items;
})
 
const openGallery = function(key){
  console.log('open');
  lightboxShow.value = true;
  lightboxIndex.value = key;
}

</script>

<style scoped>
 
 
 
.desc {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-direction: row;
  /* align-items: flex-start; */
  justify-content: flex-start;
}

.desc.enable_photo {
}

.desc .body_photos {
    display: none;
}

.desc.enable_photo .body_photos {
  display: inline-block;
  width: 20%;
  flex-shrink: 0;
}

.desc .body_photos .top_photo {
    width: 100%;
}

.desc .body_photos .small {
    display: inline-block;
    height: 100px;
    width: 100%;
    overflow-x: auto;;
    overflow-y: hidden;
}

.desc .body_photos .small_item {
    width: 100px;
}

.desc .body_photos .small_item img {
    width: 100%;
}

.desc .body_info {
    vertical-align: top;
    width: 100%;
    margin-left: 0px;
}
.desc.enable_photo .body_info {
    width: 100%;
    margin-left: 30px;
}

.desc .body_info .text {
    margin-top: 0px;
    white-space: pre-wrap;
}

.desc .body_info .text p:first-child {
  margin-top: 0px;
}

.desc .body_info .web {
    margin-top: 10px;
}
.desc .body_info .link_out {
    margin-top: 10px;
}

.desc .body_info .link_out .lnk{

}

.desc .body_info .title {
    margin-top: 5px;
}

/* AWARDS DIARIES */

.desc .body_info .trophys {
    overflow: hidden;
    max-height: 135px;
}

.desc .body_info .trophys .item {
        display: inline-block;
    width: 90px;
    text-align: center;
    vertical-align: top;
    margin-right: 10px;
}

.desc .body_info .trophys .trophy {
    width: 50px; /* padding: 0px 2.4rem; *//* margin-top: 0.5rem; */
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.desc .body_info .trophys .name {
    font-size: 0.75rem;
    color: #494949;
}

.desc .body_info .trophys .item .avatar {
    width: 100%;
    display: inline-block;
    position: relative;
}

.desc .body_info .trophys .item .avatar_img {
    width: 100%;
    border-radius: 5px;
}

.desc .body_info .trophys .item .name_diary {
    color: var(--un-text-color);
    display: inline-block;
    width: 100%;
    max-height: 40px;
    overflow: hidden;
}


@container pb (max-width: 600px) {
  .desc{
    flex-direction: column;
  }
  .desc.enable_photo .body_info{
    margin-top: 1rem;
    margin-left: 0;
  }
  .desc.enable_photo .body_photos {
    width: 100%;
  }
}



/* INFO GALLERY */

.info_gallery {}

.info_gallery .cover {
    position: relative;
    width: 100%;
}

/* .info_gallery .cover:after {
  content: "";
  display: block;
  padding-bottom: 100%;
} */
.info_gallery .cover img {
  width: 100%;
  object-fit: cover;
  border-radius: 3px;
}
.info_gallery .cover.square img {
  aspect-ratio: 1/1;
}

.body_info .thumb {
    text-align: left;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.body_info .thumb>a {
    width: 50px;
    height: 50px;
    /*object-fit: cover;*/
    overflow: hidden;
    display: inline-block;
    margin: 2px 3px 0 0px;
}

.body_info .thumb>a>img {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    object-fit: cover;
}

/**/



</style>
